import styles from "./AMExplorerLanding.module.css";
import macbook from "../../Assets/Images/MacBook Pro 16-inch Space Black Portrait.png";
// import hackyshite from "../../Assets/Images/Desktop - 6 .svg";
import hackyshite from "../../Assets/Images/hackyshite.png";
import { usePopup } from "../../Context/PopupContext";
import { Popup } from "../../Components/Popup/Popup";

export const AMExplorerLanding = () => {
  const { isPopupOpen, setIsPopupOpen } = usePopup();

  return (
    <main className={styles.main}>
      <div className={styles.bannerSection}>
        <div className={styles.bannerText}>
          <p>
            Embark on your metal additive journey by choosing the right parts
            from existing designs. Save time and money by unlocking benefits of
            3D printing.{" "}
          </p>
          <button
            onClick={() => {
              window.location.href =
                "https://www.intechadditive.com/contact-us.php";
            }}
          >
            Request Demo
          </button>
        </div>
        <img src={macbook} className={styles.macbook}></img>
      </div>

      <Popup
        isOpen={isPopupOpen}
        onClose={() => {
          setIsPopupOpen(false);
        }}
      />

      <div>
        <img
          src={hackyshite}
          style={{ width: "100%" }}
          alt="Explorer Info"
        ></img>
      </div>

      <div className={styles.footer}>
        <div>Intech Additve Solutions, Bangalore</div>
        <div style={{ fontWeight: "500" }}>
          <span>Contact Us : </span>
          marketing@intechadditive.com , +91 6366-450-455
        </div>
      </div>

      {/* <div className={styles.informationSection}> */}
      {/*   <div className={styles.infoSubSection}> */}
      {/*     <div> */}
      {/*       <div className={styles.infoHeader}> */}
      {/*         <div className={styles.infoTitle}>What does AMExplorer do?</div> */}
      {/*         <div className={styles.slantBorder}></div> */}
      {/*       </div> */}
      {/*       <div className={styles.infoDescription}> */}
      {/*         AMExplorer analyses thousands of parts and specifies the right */}
      {/*         parts which are suitable for 3D printing. It provides a report of */}
      {/*         economic benefits, material properties and inventory management. */}
      {/*       </div> */}
      {/*     </div> */}
      {/*     <div> */}
      {/*       <img src={baseplate}></img> */}
      {/*     </div> */}
      {/*   </div> */}
      {/**/}
      {/*   <div> */}
      {/*     <div></div> */}
      {/*     <div></div> */}
      {/*   </div> */}
      {/*   <div></div> */}
      {/* </div> */}
    </main>
  );
};
