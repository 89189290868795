import "./navbar.css";
import { useEffect, useNavigate } from "react";
import { Link, useLocation } from "react-router-dom";
import Explorerlogo from "../../Assets/Brand/explorerLogo.png";
import { usePopup } from "../../Context/PopupContext";

export const Navbar = () => {
  const location = useLocation();

  const { setIsPopupOpen } = usePopup();
  // const handleLogin = () => {
  //   window.location.replace("https://dev-auth.intechadditive.com");
  // };

  const ConditionalNavRight = () => {
    switch (location.pathname) {
      // case "/":
      // case "/AMCosting":
      //   return (
      //     <>
      //       <nav className="main-nav">
      //         <div className="logo">
      //           <Link to="/">
      //             <img src={logo}></img>
      //           </Link>
      //         </div>
      //         <div className="navbar-right">
      //           <div className="navbar-dropdown">
      //             <div className="navbar-dropdown-header">
      //               Softwares <VscTriangleDown />
      //             </div>
      //
      //             <div className="navbar-dropdown-menu">
      //               {/* <Link to="/AMCosting"> */}
      //               <a href="/AMCosting">
      //                 <img src="/logo.png" alt="AMC" />
      //                 <div className="navbar-dropdown-menu-header">
      //                   <h1>AMCosting</h1>
      //                   <h4>Calculate Cost Per Part</h4>
      //                 </div>
      //               </a>
      //
      //               {/* </Link> */}
      //               <a href="/AMExplorer">
      //                 <img src="/AMExplorer.ico" alt="AME" />
      //                 <div className="navbar-dropdown-menu-header">
      //                   <h1>AMExplorer</h1>
      //                   <h4>Calculate Suitabilty</h4>
      //                 </div>
      //               </a>
      //             </div>
      //           </div>
      //
      //           <div className="login-signup">
      //             <button className="log-in btn" onClick={handleLogin}>
      //               Login
      //             </button>
      //             {/* <button className="sign-up btn">Sign Up</button> */}
      //           </div>
      //         </div>
      //       </nav>
      //     </>
      //   );

      case "/AMExplorer":
        return (
          <div className="explorer-landing-nav">
            <div className="explorer-logo">
              <Link to="/">
                <img src={Explorerlogo}></img>
              </Link>
            </div>

            <div className="nav-links">
              <div
                className="request-demo"
                onClick={() => {
                  window.location.href =
                    "https://www.intechadditive.com/contact-us.php";
                }}
              >
                Request demo
              </div>
              <div
                className="download-report"
                onClick={() => {
                  setIsPopupOpen(true);
                }}
              >
                {/* <a href={pdf} download> */}
                Download report
                {/* </a> */}
              </div>
            </div>
          </div>
        );

      default:
        return <> </>;
    }
  };
  return <ConditionalNavRight />;
};
