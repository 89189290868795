import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Popup.css"; // Import module CSS

import intechLogo from "../../Assets/Brand/Intech_logo.png";
import { MdClose } from "react-icons/md";
import pdf from "../../Assets/Images/AMExplorer Report 4.pdf";

export const Popup = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const apiUrl = "http://localhost:4000/api/v1/user";
  // const apiUrl = config.url.REACT_APP_AM_COSTING;
  const [pdfData, setPdfData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(pdf); // Replace with actual path
        if (!response.ok) {
          throw new Error("Failed to load PDF");
        }
        const data = await response.blob();
        setPdfData(data);
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    };

    fetchData();
  }, []);

  const downloadPdf = () => {
    if (pdfData) {
      const fileUrl = window.URL.createObjectURL(pdfData);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", "Intech report.pdf");
      link.click();
      window.URL.revokeObjectURL(fileUrl);
    } else {
      console.error("PDF data not available for download");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Handle form submission here (e.g., send data to server)
    const payload = {
      email: email,
      phone: phone,
    };
    downloadPdf();
    // axios
    //   .post(apiUrl, payload)
    //   .then((response) => {
    //   })
    //   .catch((error) => {
    //     console.error("Error submitting form:", error);
    //   });
    console.log("Email:", email);
    console.log("Phone:", phone);
    onClose(); // Close popup after submission
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "phone") {
      setPhone(value);
    }
  };

  return (
    <div className={`popup ${isOpen ? "active" : ""}`}>
      <div className="popup-content">
        <div className="popup-logo">
          <img src={intechLogo}></img>

          <button className="close-btn" onClick={onClose}>
            <MdClose />
          </button>
        </div>

        <div className="popup-description">
          Fill out the form below, and download the report right away!
        </div>

        <div className="popup-form-container">
          <form onSubmit={handleSubmit}>
            <label htmlFor="email">Email :</label>

            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleInputChange}
              required
            />

            <label htmlFor="phone">Phone Number :</label>

            <input
              type="tel"
              minLength={10}
              maxLength={10}
              id="phone"
              name="phone"
              value={phone}
              onChange={handleInputChange}
              required
            />

            <button type="submit">Download now!</button>
          </form>
        </div>
      </div>
    </div>
  );
};
