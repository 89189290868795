import { useContext } from "react";
import { useState } from "react";
import { createContext } from "react";

const popupContext = createContext();

const PopupProvider = ({ children }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <popupContext.Provider
      value={{
        isPopupOpen,
        setIsPopupOpen,
      }}
    >
      {children}
    </popupContext.Provider>
  );
};

const usePopup = () => useContext(popupContext);

export { usePopup, PopupProvider };
