import { Routes, Route, useNavigate } from "react-router-dom";
import { Navbar } from "./Components";
import { Error404 } from "./pages";
import { AMExplorerLanding } from "./pages/AMExplorerLanding/AMExplorerLanding";
import { useEffect } from "react";
import { PopupProvider } from "./Context/PopupContext";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("AMExplorer");
  }, []);

  return (
    <div className="App">
      <PopupProvider>
        <Navbar />
        <Routes>
          <Route path="/AMExplorer" element={<AMExplorerLanding />} />
          {/* <Route path="*" element={<Error404 />} /> */}
        </Routes>
      </PopupProvider>
    </div>
  );
}

export default App;
